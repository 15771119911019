@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Avenir';
  src: local('Avenir'),
    url(./assets/fonts/AvenirNextFont.ttf) format('ttf');
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Avenir', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  overflow-x: hidden !important;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}